<template>
	<b-container fluid>
		<b-row>
			<b-col md="12">
				<iq-card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Lista de Tratamientos</h4>
					</template>
					<template v-slot:headerAction>
						<b-button
							v-if="role != 'Paciente'"
							variant="primary"
							@click="
								showModal('add', {
									diagnostic_title: null,
									description: null,
									objective: null,
									medication: null,
								})
							"
						>
							Agregar
						</b-button>
					</template>
					<template v-slot:body>
						<DataTable
							v-if="treatments"
							:data="treatments"
							:columns="columns"
							:fields-search="[
								'doctor',
								'patient',
								'date',
								'diagnostic_title',
								'description_treatment',
								'objective',
								'medication',
							]"
						>
							<template #rows="{ row, rowIndex }">
								<tr>
									<th scope="row">{{ rowIndex + 1 }}</th>
									<td>
										{{ row.date }}
									</td>
									<td>
										{{ row.doctor }}
									</td>
									<td>
										{{ row.patient }}
									</td>
									<td>
										{{ row.diagnostic_title }}
									</td>
									<td>
										{{ row.description }}
									</td>
									<td>
										{{ row.objective }}
									</td>
									<td>
										{{ row.medication }}
									</td>
									<td>
										<b-button-group>
											<b-button
												v-user-can="'eliminar tratamientos'"
												variant="danger"
												@click="destroy(row.id)"
											>
												<i class="ri-delete-bin-line m-0"></i>
											</b-button>
										</b-button-group>
									</td>
								</tr>
							</template>
						</DataTable>
					</template>
				</iq-card>
			</b-col>
		</b-row>
		<ModalAdd @getData="getData" :type="type" :data="data" />
	</b-container>
</template>

<script>
import { Toast } from "./../../../mixins/toast";
import { xray } from "../../../config/pluginInit";
import api from "./../../../services/api";
import DataTable from "./../../../components/DataTable.vue";
import Swal from "sweetalert2";
import ModalAdd from "./ModalAdd.vue";

export default {
	components: {
		DataTable,
		ModalAdd,
	},
	data() {
		return {
			treatments: [],
			data: {},
			columns: [
				{
					name: "Fecha",
					field: "date",
					sort: "",
				},
				{
					name: "Doctor",
					field: "doctor",
					sort: "",
				},
				{
					name: "Paciente",
					field: "patient",
					sort: "",
				},
				{
					name: "Diagnóstico",
					field: "diagnostic_title",
					sort: "",
				},
				{
					name: "Tratamiento",
					field: "description",
					sort: "",
				},
				{
					name: "Objetivo",
					field: "objective",
					sort: "",
				},
				{
					name: "Medicación",
					field: "medication",
					sort: "",
				},
			],
			type: "add",
			role: null,
		};
	},
	mounted() {
		xray.index();
		this.getData();
		let user = JSON.parse(localStorage.getItem("user"));
		this.role = user.role;
	},
	computed: {},
	methods: {
		async getData() {
			try {
				const { data } = await api.get("/treatments");
				this.treatments = data.data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		showModal(type, data) {
			this.data = data;
			this.type = type;
			this.$bvModal.show("modal-add-treatment");
		},
		destroy(id) {
			Swal.fire({
				icon: "warning",
				title: "Seguro de eliminar",
				showDenyButton: false,
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.isConfirmed) {
					api
						.delete(`/treatments/${id}`)
						.then((res) => {
							if (res.status === 200) {
								Toast.fire({
									icon: "success",
									title: "Eliminado correctamente",
								});
								this.getData();
							}
						})
						.catch((error) => {
							Toast.fire({
								icon: "error",
								title: error.message,
							});
						});
				}
			});
		},
	},
};
</script>

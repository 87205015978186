<template>
	<b-modal
		id="modal-add-treatment"
		size="xl"
		title="Agregar Tratamiento"
		no-close-on-backdrop
		hide-footer
	>
		<ValidationObserver ref="form" v-slot="{ handleSubmit }">
			<b-form @submit.stop.prevent="handleSubmit(onSubmit)">
				<b-row>
					<b-form-group class="col-md-4" label="Fecha:" label-for="date">
						<ValidationProvider name="Fecha" rules="required" v-slot="{ errors }">
							<b-form-input
								id="date"
								type="date"
								v-model="form.date"
								:class="errors.length > 0 ? ' is-invalid' : ''"
							/>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>

					<b-form-group
						v-if="role != 'Doctor'"
						class="col-md-4"
						label="Doctor:"
						label-for="doctor_id"
					>
						<ValidationProvider name="Doctor" rules="required" v-slot="{ errors }">
							<v-select
								:options="doctors"
								label="full_name"
								:reduce="(doctor) => doctor.id"
								v-model="form.doctor_id"
								:class="errors.length > 0 ? ' is-invalid' : ''"
							/>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>

					<b-form-group class="col-md-4" label="Paciente:" label-for="patient_id">
						<ValidationProvider name="Paciente" rules="required" v-slot="{ errors }">
							<v-select
								:options="patients"
								label="full_name"
								:reduce="(patient) => patient.id"
								v-model="form.patient_id"
								:class="errors.length > 0 ? ' is-invalid' : ''"
							/>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>

					<b-form-group
						class="col-md-4"
						label="Diagnostico:"
						label-for="diagnostic_title"
					>
						<ValidationProvider name="Diagnostico" rules="required" v-slot="{ errors }">
							<b-form-input
								v-model="form.diagnostic_title"
								type="text"
								placeholder="Diagnostico"
								:class="errors.length > 0 ? ' is-invalid' : ''"
							></b-form-input>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>
					<b-form-group class="col-md-4" label="Descripción:" label-for="description">
						<ValidationProvider name="Descripción" rules="required" v-slot="{ errors }">
							<b-form-input
								v-model="form.description"
								type="text"
								placeholder="Descripción"
								:class="errors.length > 0 ? ' is-invalid' : ''"
							></b-form-input>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>
					<b-form-group class="col-md-4" label="Objetivo:" label-for="objective">
						<ValidationProvider name="Objetivo" rules="required" v-slot="{ errors }">
							<b-form-input
								v-model="form.objective"
								type="text"
								placeholder="Objetivo"
								:class="errors.length > 0 ? ' is-invalid' : ''"
							></b-form-input>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>
					<b-form-group class="col-md-12" label="Medicación:" label-for="medication">
						<ValidationProvider name="Medicación" rules="required" v-slot="{ errors }">
							<b-form-textarea
								id="medication"
								v-model="form.medication"
								placeholder="Ingrese la medicación..."
								rows="2"
								max-rows="4"
								:class="errors.length > 0 ? ' is-invalid' : ''"
							/>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>
					<div class="col-md-12 w-100">
						<b-button type="submit" variant="primary" class="float-right">
							Guardar
						</b-button>
					</div>
				</b-row>
			</b-form>
		</ValidationObserver>
	</b-modal>
</template>

<script>
import { Toast } from "../../../mixins/toast";
import api from "./../../../services/api";

export default {
	components: {},
	props: {
		data: {
			type: Object,
			required: false,
		},
	},
	mounted() {
		this.getDoctors();
		this.getPatients();
		const currentDate = new Date();
		this.form.date = currentDate.toISOString().slice(0, 10);
		let user = JSON.parse(localStorage.getItem("user"));
		this.role = user.role;
	},
	watch: {
		data: function (newValue, oldValue) {
			if (this.role === "Doctor") {
				let user = JSON.parse(localStorage.getItem("user"));
				this.form.doctor_id = user.doctor_id;
			} else {
				this.form.doctor_id = newValue.doctor_id;
			}
			this.form.patient_id = newValue.patient_id;
			this.form.appointment_id = newValue.id;
		},
	},
	computed: {},
	methods: {
		onSubmit() {
			this.saveTreatment();
		},
		async saveTreatment() {
			try {
				await api.post("/treatments", this.form);
				this.$bvModal.hide("modal-add-treatment");
				this.$emit("getData");
				Toast.fire({
					icon: "success",
					title: "Tratamiento Creado",
				});
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async getDoctors() {
			try {
				const { data } = await api.get("/lists/doctors");
				this.doctors = data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async getPatients() {
			try {
				const { data } = await api.get("/lists/patients");
				this.patients = data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
	},
	data() {
		return {
			form: {
				doctor_id: null,
				patient_id: null,
				appointment_id: null,
				date: null,
				diagnostic_title: null,
				description: null,
				objective: null,
				medication: null,
			},
			doctors: [],
			patients: [],
			role: null,
		};
	},
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('iq-card',{scopedSlots:_vm._u([{key:"headerTitle",fn:function(){return [_c('h4',{staticClass:"card-title"},[_vm._v("Lista de Tratamientos")])]},proxy:true},{key:"headerAction",fn:function(){return [(_vm.role != 'Paciente')?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.showModal('add', {
								diagnostic_title: null,
								description: null,
								objective: null,
								medication: null,
							})}}},[_vm._v(" Agregar ")]):_vm._e()]},proxy:true},{key:"body",fn:function(){return [(_vm.treatments)?_c('DataTable',{attrs:{"data":_vm.treatments,"columns":_vm.columns,"fields-search":[
							'doctor',
							'patient',
							'date',
							'diagnostic_title',
							'description_treatment',
							'objective',
							'medication',
						]},scopedSlots:_vm._u([{key:"rows",fn:function({ row, rowIndex }){return [_c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(rowIndex + 1))]),_c('td',[_vm._v(" "+_vm._s(row.date)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.doctor)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.patient)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.diagnostic_title)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.description)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.objective)+" ")]),_c('td',[_vm._v(" "+_vm._s(row.medication)+" ")]),_c('td',[_c('b-button-group',[_c('b-button',{directives:[{name:"user-can",rawName:"v-user-can",value:('eliminar tratamientos'),expression:"'eliminar tratamientos'"}],attrs:{"variant":"danger"},on:{"click":function($event){return _vm.destroy(row.id)}}},[_c('i',{staticClass:"ri-delete-bin-line m-0"})])],1)],1)])]}}],null,false,453892801)}):_vm._e()]},proxy:true}])})],1)],1),_c('ModalAdd',{attrs:{"type":_vm.type,"data":_vm.data},on:{"getData":_vm.getData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }